.leftMenu {
  position: fixed; /* Stay in place */
  height: 100%; /* 100% Full-height */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  overflow: auto;
  white-space: nowrap;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 10px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
.logo-container {
  height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid hsla(0, 26%, 86%, 0.603);
  margin-bottom: 5px;
}
.logo-text {
  font-family: Arial, Helvetica, sans-serif;
  color: #818181;
  margin-top: 10px !important;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
}
.logo-img{
  width: 150px;
  height: 50px;
  margin: 0;
}

.leftMenu .leftMenuItem {
  padding: 0px 0px 0px 15px;
  width: 250px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 100;
  color: #818181;
  transition: 0.5s;
}
.ant-layout, 
.custom-card {
  /* min-height: 90vh;  */
  background: #f2f6f8;
  color: #333;
}

.sweep-to-right {
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: color 1000ms;
  transition: color 1000ms;
}
.sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e4eaee;
  color: #333;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out;
}
.sweep-to-right:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.leftMenu .leftMenuItem:hover,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: #e4eaee;
  color: #333;
}
.login-title {
  color: #636363;
  font-weight: 500;
  font-size: 25px;
}
.logout_link_color {
  color: rgba(0, 0, 0, 0.65);
}
.text-center{
  text-align:center;
}
.ant-table-body {
  background: #fff;
  color: #777;
  font-size: 14px;
  line-height: 1.4rem;
  border-style: solid;
  border-color: #c6d2d9;
  border-width: 1px 1px 4px 1px;
  border-radius: 4px 4px 5px 5px;
}
/* Margin */
.m-0 {
  margin: 0%;
}
.m-1 {
  margin: 10px;
}
.m-2 {
  margin: 20px;
}
.m-3 {
  margin: 30px;
}
.m-4 {
  margin: 40px;
}
.m-5 {
  margin: 50px;
}
/* Top Margin */
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mt-5 {
  margin-top: 50px;
}
/* Bottom Margin */
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.mb-5 {
  margin-bottom: 50px;
}
/* Left Margin */
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 30px;
}
.ml-4 {
  margin-left: 40px;
}
.ml-5 {
  margin-left: 50px;
}
/* Right Margin */
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.mr-3 {
  margin-right: 30px;
}
.mr-4 {
  margin-right: 40px;
}
.mr-5 {
  margin-right: 50px;
}


.w-100 {
  width: 100%;
}

.ant-input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 5px 5px;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid #c8ccd4;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
}

.ant-input:hover {
  background: #fff;
}

.ant-input:focus {
  background: none;
  box-shadow: none;
  outline: none;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 2px;
}

.ant-layout-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.forgot_password {
  float: right;
  margin-top: 10px;
}
.cursor_pointer {
  cursor: pointer;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
.card-body {
  font-weight: 500;
  color: #777;
  font-size: 15x;
  line-height: 1.8rem;
}
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}